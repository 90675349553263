import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function DataWithNarrowImageBlock(props) {
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background }>
            <Container className='data-image-block'>
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead'>{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={7}>
                        <ReactMarkdown>{props.data.description}</ReactMarkdown>
                    </Col>
                    <Col md={5} className='image-container'>
                        <Row>
                            {props.data.ImageListItem &&
                                props.data.ImageListItem.map((item) => {
                                    return (
                                        <Col xs={6} md={12} lg={6} style={{textAlign:'center'}}>
                                            {item.image && 
                                                <img
                                                    className='w-75 d-block mx-auto mb-3'
                                                    src={siteMetadata.apiURL + item.image.url}
                                                    alt=''
                                                    key={item.id}
                                                />
                                            }                                          
                                            <ReactMarkdown>{item.linkData}</ReactMarkdown>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
