import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { siteMetadata } from "../../../../gatsby-config";
import ReactMarkdown from "react-markdown";

export default function ComplexDataWithImage(props) {
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background } >
            <Container fluid className="midpad pt-0 pb-0 complex-data-container">
                <Row>
                    <Col md={12} className="text-center">
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead banner-subtitle mb-5">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>

                <Row className="image-container">
                    <Col md={6} className="text-center d-flex flex-column align-items-center align-items-md-end justify-content-between">
                        <p className="image-title">{props.data.imagetitle}</p>
                        {props.data.image && <img loading="lazy" className="img-fluid" src={siteMetadata.apiURL + props.data.image.url} alt={props.data.image.alternativeText} />}
                    </Col>
                    <Col md={6}>
                        <Row md={12} className="list-container">
                            <ReactMarkdown className="list-text">{props.data.description}</ReactMarkdown>
                        </Row>
                        <Row md={12} className="bottom-container">
                            <Col md={7} className="bottom-list-container">
                                <ReactMarkdown>{props.data.bottomText}</ReactMarkdown>
                                {props.data.link && (
                                    <div className="btn-container d-flex justify-content-center d-md-block">
                                        <a className="text-uppercase find-out-btn btn btn-green" href={props.data.link.ButtonRoute}>
                                            {props.data.link.ButtonText}
                                        </a>
                                    </div>
                                )}
                            </Col>
                            <Col md={5} className="quote-container">
                                <p className="quote">{props.data.quote}</p>
                                <p className="author">
                                    <b>{props.data.quoteAuthor}</b>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {props.data.ctaButton && <a style={{margin:'auto'}} href={props.data.ctaButton.ButtonRoute} className='open-demo-modal btn btn-lg btn-green mt-md-5'> {props.data.ctaButton.ButtonText}</a>}
                </Row>
            </Container>
        </section>
    );
}
