import React from "react";
import { siteMetadata } from "../../../../gatsby-config";
import grayCircle from "../../../assets/images/banner-grey-circle.png";
import blueCircle from "../../../assets/images/banner-blue-circle.png";

export default function MainBannerWithImage(props) {
    const style = {
        backgroundImage: "url(" + siteMetadata.apiURL + props.data.bannerImage.url + ")",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };

    return (
        <header className="header-banner" style={style}>
            <img className="grey-circle" src={grayCircle} alt="Circle icon" />
            <img className="blue-circle" src={blueCircle} alt="Circle icon" />

            <div className="content">
                <div className="heading-wrap">
                    <h1>{props.data.title}</h1>
                </div>
                <div className="intro-wrap">
                    <p className="description">{props.data.description}</p>
                    {props.data.bannerButton && (
                        <a style={{ margin: "10px" }} href={props.data.bannerButton.ButtonRoute} className="btn btn-medium btn-green mt-sm-4 mr-sm-4 d-block d-sm-inline-block">
                            {props.data.bannerButton.ButtonText}
                        </a>
                    )}
                    {props.data.talkToUs && (
                        <a style={{ margin: "10px" }} href={props.data.talkToUs.ButtonRoute} className="btn btn-medium btn-green mt-sm-4 mr-sm-4 d-block d-sm-inline-block">
                            {props.data.talkToUs.ButtonText}
                        </a>
                    )}
                </div>
            </div>
        </header>
    );
}
