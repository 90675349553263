import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UnorderedListBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    // header font color from the backend
    const textColor = props.data.columnHeaderColor ? " " + props.data.columnHeaderColor.textColor + "-text " : " "

    return (
        <section className={background}>
            <Container className="unordered-list-banner">
                <Row>
                    <Col md={12} className="text-center">
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="content justify-content-center">
                    {props.data.ListWithHeaderAndIcon &&
                        props.data.ListWithHeaderAndIcon.map((item) => {
                            return (
                                <Col md={props.data.columns === "three" ? 4 : 6} className="item">
                                    <Row className="align-items-center heading">
                                        {item.icon && (
                                            <Col xs={1} className="img-container">
                                                <img src={siteMetadata.apiURL + item.icon.url} alt="" />
                                            </Col>
                                        )}
                                        <Col className="p-0">
                                            <p className={"item-title" + textColor}>{item.headerText}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p>
                                            <ReactMarkdown>{item.text}</ReactMarkdown>
                                        </p>
                                    </Row>
                                </Col>
                            )
                        })}
                </Row>
                <div className="bottom-text d-sm-flex w-75 m-auto">
                    <p className="text-center">
                        <FontAwesomeIcon size={"2x"} icon={["fal", props.data.fontawesomeIconBottom]} className="icon mr-3" />
                    </p>
                    <ReactMarkdown>{props.data.bottomText}</ReactMarkdown>
                </div>
                {props.data.ctaButton && (
                    <Row>
                        <Col>
                            <p className="d-flex d-md-block justify-content-center text-center">
                                <a className="btn btn-medium btn-green" href={props.data.ctaButton.ButtonRoute}>
                                    {props.data.ctaButton.ButtonText}
                                </a>
                            </p>
                        </Col>
                    </Row>
                )}
            </Container>
        </section>
    )
}
