import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { siteMetadata } from "../../../../gatsby-config";

export default function ArticleListBanner(props) {
    //   TODO: changable listHeader bg color and icon
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " ";

    return (
        <section className={'article-list-banner ' + background}>
            <Container fluid className="respad">
                {props.data.topTitle && <h2 className="banner-title">{props.data.topTitle}</h2>}
                <Row>
                    <Col lg={4} sm={12}>
                        <div className="rsrc_hdr grn rscr_icon_a d-flex align-items-center article-title">
                            <h3 className="p-0 m-0">{props.data.listHeader}</h3>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Col lg={12} className="wbg row w-100 m-auto">
                            {props.data.articleList.map((article) => {
                                return (
                                    <Col lg={4} md={6} sm={6} xs={12}>
                                        <div className="rsrc_pnl_full">
                                            <div className="shim_a" />
                                            {article.image && <img loading="lazy" src={siteMetadata.apiURL + article.image.url} alt={article.image.alternativeText} className="img-fluid" />}
                                            <div className="rsrc_subtitle">{article.title}</div>
                                            <p>{article.description}</p>
                                            <div className="rsrc_link lnk_grn">
                                                <a href={article.moreLinkUrl} target="_blank">
                                                    {article.moreLinkText}
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Col>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
