import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function TimelineBanner(props) {

    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    // description align is from backend
    const textAlign = props.data.boxTextAlign ? " " + props.data.boxTextAlign.textAlign + "-align " : " "
    
    // header font color from the backend
    const textColor = props.data.headersColorSelection ? " " + props.data.headersColorSelection.textColor + "-text " : " "

    return (
        <section className={background}>
            <Container className="timeline-banner">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead mb-0 text-center'>{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className='features justify-content-center'>
                    {props.data.box && props.data.box.map((box) => {
                        return (
                            <Col className={'box inline-item' + textAlign} key={box.id}>
                                {/* if there is uploaded image, this image is displayed. If there is fontawesome, the icon is displayed */}
                                {box.iconImage ? (
                                <p className="text-center">
                                    <img loading='lazy' src={siteMetadata.apiURL + box.iconImage.url} alt='project change management' />
                                </p>
                                )
                                :
                                 (<p className="text-center">{box.FontAwesomeIconName}
                                    {box.fontAwesomeIconName && <FontAwesomeIcon icon={["fal", box.fontAwesomeIconName]} className="icon" />}
                                 </p>)}

                                <h3 className={"icon-box-title" + textColor}>{box.IconBoxTitle}</h3>
                                <ReactMarkdown className="icon-box-description">{box.IconBoxDescription}</ReactMarkdown>
                                <FontAwesomeIcon className="arrow-right-icon" icon={faArrowRight}></FontAwesomeIcon>
                                <FontAwesomeIcon className="arrow-down-icon" icon={faArrowDown}></FontAwesomeIcon>
                            </Col>
                        );
                    })}
                </Row>
                {props.data.ctaButton && <a href={props.data.ctaButton.ButtonRoute} className='open-demo-modal btn btn-lg btn-green'> {props.data.ctaButton.ButtonText}</a>}
            </Container>
        </section>
    )
}
