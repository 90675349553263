import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { siteMetadata } from "../../../../gatsby-config";

export default function BranchDataBanner(props) {
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background } >
            <Container className="text-center branch-data-banner">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="secondary-title-container">
                    <Col md={9}>
                        <p className="text-uppercase font-weight-bold d-inline-block">{props.data.firstGroupName}</p>
                    </Col>
                    <Col md={3}>
                        <p className="text-uppercase font-weight-bold">{props.data.SecondGroupName}</p>
                    </Col>
                </Row>
                <Row>
                    {props.data.DataColumns &&
                        props.data.DataColumns.map((column) => {
                            return (
                                <Col md={3} key={column.id} className="data-item">
                                    <p className=" font-weight-bold item-title">{column.title}</p>
                                    <div>{column.Image !== null && <img src={siteMetadata.apiURL + column.Image.url} alt="" className=" mb-4" />} </div>
                                    <p className="item-description">
                                        <ReactMarkdown>{column.description}</ReactMarkdown>
                                    </p>
                                    <div className="btn-container">
                                        <a className="text-uppercase more-btn btn-green btn" href={column.linkUrl}>
                                            {column.linkText}
                                        </a>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </section>
    );
}
