import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';

export default function ShowHideVideoInfoBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "
    // position of the video (left or right)
    const videoPosition = props.data.videoPosition === "right" ? "last" : "first";
    const [displayedId, setDisplayedId] = useState((props.data.showHideItem && props.data.showHideItem[0] ? props.data.showHideItem[0].id : null));

    const handleButtonClick = (id) => {
        setDisplayedId(id);
    };

    return (
        <div>
            <section className={'show-hiders group-a video-info-banner' + background} data-group='a'>
                <Container>
                    <Row>
                        <Col className='col-sm-6'>
                            <div className='text-container'>
                                <h2 className="banner-title">{props.data.mainTitle}</h2>
                                {/* <!-- Nav tabs --> */}
                                <ul className='nav nav-pills'>
                                    {props.data.showHideItem.map((item) => {
                                        const activeClassName = item.id === displayedId ? 'active' : '';
                                        return (
                                            <li className={'font-weight-normal ' + activeClassName}>
                                                <a className='text-decoration-none' style={{ padding: '7.5px 10px' }} data-related='a1' onClick={() => handleButtonClick(item.id)} >
                                                {item.buttonText}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>

                                {props.data.showHideItem.map((item) => {
                                    return (
                                        <div className='show-hider' style={{display: item.id === displayedId ? 'block' : 'none'}}>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>

                        <Col xs={{order: 2}} md={{order: videoPosition}} className='col-sm-6 image-container'>
                            {props.data.showHideItem.map((item) => {
                                return (
                                    <div className='show-hider show-first' data-id='a1' style={{display: item.id === displayedId ? 'block' : 'none'}} >
                                        {item.video &&
                                            <video width='100%' height='auto' autoPlay playsinline muted loop >
                                                <source src={siteMetadata.apiURL + item.video.url} type='video/mp4' />
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}
