import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"

export default function DetailedDatasBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="detailed-data-banner">
                <Row>
                    <Col md={12} className="text-center">
                        <h2 className="banner-title">{props.data.sectionTitle}</h2>
                        <ReactMarkdown className="lead">{props.data.sectionSubtitle}</ReactMarkdown>
                    </Col>
                </Row>
                {props.data.DeatiledDataItem &&
                    props.data.DeatiledDataItem.map((dataItem) => {
                        return (
                            <Col key={dataItem.id}>
                                <Row>
                                    <Col sm={2} lg={1} className="text-center">
                                        {dataItem.upperText && dataItem.upperText.icon && (
                                            <img className="icon" src={siteMetadata.apiURL + dataItem.upperText.icon.url} alt=""/>
                                        )}
                                    </Col>
                                    <Col sm={10} lg={11}>
                                        {dataItem.upperText && <ReactMarkdown>{dataItem.upperText.text}</ReactMarkdown>}
                                    </Col>
                                </Row>
                                <Row className="image-text">
                                    <Col sm={2} lg={1}></Col>
                                    <Col md={3}>
                                        {dataItem.image && <div className="light-grey-bg p-2  img-container">
                                            <img
                                                className="w-100"
                                                src={siteMetadata.apiURL + dataItem.image.url}
                                                alt=""
                                            />
                                        </div>}
                                    </Col>
                                    <Col>
                                        <ReactMarkdown>{dataItem.description}</ReactMarkdown>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
            </Container>
        </section>
    )
}
