import React from 'react';

import '../styles/styles.scss';
import '../styles/cf.css';

import BoxBanner from '../components/StrapiComponents/banners/BoxBanner';
import CarosuelBanner from '../components/StrapiComponents/banners/CarosuelBanner';
import IconBanner from '../components/StrapiComponents/banners/IconBanner';
import MainBannerWithImage from '../components/StrapiComponents/banners/MainBannerWithImage';
import StatisticsBanner from '../components/StrapiComponents/banners/StatisticsBanner';
import ButtonBanner from '../components/StrapiComponents/banners/ButtonBanner';
import QuoteBanner from '../components/StrapiComponents/banners/QuoteBanner';
import TitleAndDescriptionBanner from '../components/StrapiComponents/banners/TitleAndDexcriptionBanner';
import DataWithVideoBanner from '../components/StrapiComponents/banners/DataWithVideoBanner';
import DataWithNotAutoVideoBanner from '../components/StrapiComponents/banners/DataWithNotAutoVideoBanner';
import ImageCarousel from '../components/StrapiComponents/banners/ImageCarousel';
import SmallerImageBanner from '../components/StrapiComponents/banners/SmallerImageBanner';
import ShowHideVideoInfoBanner from '../components/StrapiComponents/banners/ShowHideVideoInfoBanner';
import TabsWithTextButtonAndImage from '../components/StrapiComponents/banners/TabsWithTextButtonAndImage';
import ArticleListBanner from '../components/StrapiComponents/banners/ArticleListBanner';
import InfoCardsBanner from '../components/StrapiComponents/banners/InfoCardsBanner';
import InfoBlockBanner from '../components/StrapiComponents/banners/InfoBlockBanner';
import DataWithImageBanner from '../components/StrapiComponents/banners/DataWithImageBanner';
import Header from '../components/StrapiComponents/Header';
import Footer from '../components/StrapiComponents/Footer';
import ComplexDataWithImage from '../components/StrapiComponents/banners/ComplexDataWithImage';
import IntroBoxes from '../components/StrapiComponents/banners/IntroBoxes';
import TwoImageWithLinkBanner from '../components/StrapiComponents/banners/TwoImageWithLinkBanner';
import NumberedListBanner from '../components/StrapiComponents/banners/NumberedListBanner';
import NumberedListSimpleBanner from '../components/StrapiComponents/banners/NumberedListSimpleBanner';
import DesignedInfosWithImage from '../components/StrapiComponents/banners/DesignedInfosWithImage';
import LogosWithLinksAndButtons from '../components/StrapiComponents/banners/LogosWithLinksAndButtons';
import DividedDataBoxes from '../components/StrapiComponents/banners/DividedDataBoxes';
import ClassicLinksAndButtonsBanner from '../components/StrapiComponents/banners/ClassicLinksAndButtonsBanner';
import ImageListBanner from '../components/StrapiComponents/banners/ImageListBanner';
import DataWithNarrowImageBlock from '../components/StrapiComponents/banners/DataWithNarrowImageBlock';
import InputsOutputsBanner from '../components/StrapiComponents/banners/InputsOutputsBanner';
import TableBanner from '../components/StrapiComponents/banners/TableBanner';
import ImageAndQuoteBanner from '../components/StrapiComponents/banners/ImageAndQuoteBanner';
import UnorderedListBanner from '../components/StrapiComponents/banners/UnorderedListBanner';
import DetailedDatasBanner from '../components/StrapiComponents/banners/DetailedDatasBanner';
import BranchDataBanner from '../components/StrapiComponents/banners/BranchDataBanner';
import ArrowDataBanner from '../components/StrapiComponents/banners/ArrowDataBanner';
import ResourceAdviser from '../components/StrapiComponents/banners/ResourceAdviser';
import MediaWithFormBanner from '../components/StrapiComponents/banners/MediaWithFormBanner';
import SimpleImage from '../components/StrapiComponents/banners/SimpleImage';
import CoreProgramOverviewBanner from '../components/StrapiComponents/banners/CoreProgramOverViewBanner';
import IconTextList from '../components/StrapiComponents/banners/IconTextList';
import TalkToUsBanner from '../components/StrapiComponents/banners/TalkToUsBanner';
import EmbedVideoBanner from '../components/StrapiComponents/banners/EmbedVideoBanner';
import SimpleText from '../components/StrapiComponents/banners/SimpleText';
import QuoteWithImageBanner from '../components/StrapiComponents/banners/QuoteWithImageBanner';
import SimpleTable from '../components/StrapiComponents/banners/SimpleTable';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import SystemIntroduction from '../components/StrapiComponents/banners/SystemIntroduction';
import MultipleCtaButtons from '../components/StrapiComponents/components/MultipleCtaButtons';
import TextWithFavicon from '../components/StrapiComponents/banners/TextWithFavicon';
import DataColumnBanner from '../components/StrapiComponents/banners/DataColumnBanner';
import FooterCTA from '../components/StrapiComponents/banners/FooterCTA';
import TimelineBanner from '../components/StrapiComponents/banners/TimelineBanner';
import DataWithMultipleImage from '../components/StrapiComponents/banners/DataWithMultipleImage';
import ComplexTabPanel from '../components/StrapiComponents/banners/ComplexTabPanel';

library.add(fal);


//This isnt even close to clean solution. Have to pair string with component in an object, and get it by key.
export default function Template(props) {

    const page = [
        <Header siteMetadata={props.pageContext.siteMetadata}/>,

        props.pageContext.data.map((content) => {
            if ( content.strapi_component === 'main-banners.details-with-link-box') {
                return <BoxBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.main-banner') {
                return <MainBannerWithImage data={content} />;
            } else if ( content.strapi_component === 'component.client-carosuel') {
                return <CarosuelBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.statistic-banner') {
                return <StatisticsBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.icon-banner' ) {
                return <IconBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.button-banner' ) {
                return <ButtonBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.quote-banner' ) {
                return <QuoteBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.title-and-description' ) {
                return <TitleAndDescriptionBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.data-with-video-banner' ) {
                return <DataWithVideoBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.data-with-not-auto-video-banner' ) {
                return <DataWithNotAutoVideoBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.image-carousel' ) {
                return <ImageCarousel data={content} />;
            } else if ( content.strapi_component === 'main-banners.smaller-image-banner' ) {
                return <SmallerImageBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.show-hide-video-info-banner' ) {
                return <ShowHideVideoInfoBanner data={content} />;
            } else if ( content.strapi_component === 'component.tabs-of-texts-and-images') {
                return <TabsWithTextButtonAndImage data={content} />;
            } else if ( content.strapi_component === 'main-banners.article-list-banner' ) {
                return <ArticleListBanner data={content} />;
            } else if ( content.strapi_component === 'component.info-cards-banner') {
                return <InfoCardsBanner data={content} />;
            } else if (content.strapi_component === 'main-banners.info-block') {
                return <InfoBlockBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.data-with-image-banner' ) {
                return <DataWithImageBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.complex-data-with-image') {
                return <ComplexDataWithImage data={content} />;
            } else if ( content.strapi_component === 'main-banners.intro-boxes' ) {
                return <IntroBoxes data={content} />;
            } else if ( content.strapi_component === 'main-banners.two-image-with-link-banner') {
                return <TwoImageWithLinkBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.numbered-list-banner') {
                return <NumberedListBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.numbered-list-simple-banner' ) {
                return <NumberedListSimpleBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.designed-infos-with-image-banner' ) {
                return <DesignedInfosWithImage data={content} />;
            } else if ( content.strapi_component === 'main-banners.logos-with-links-and-buttons') {
                return <LogosWithLinksAndButtons data={content} />;
            } else if ( content.strapi_component === 'main-banners.divided-data-boxes' ) {
                return <DividedDataBoxes data={content} />;
            } else if ( content.strapi_component ==='main-banners.classic-links-and-buttons-banner' ) {
                return <ClassicLinksAndButtonsBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.image-list-banner' ) {
                return <ImageListBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.data-with-narrow-image-block') {
                return <DataWithNarrowImageBlock data={content} />;
            } else if ( content.strapi_component === 'main-banners.inputs-outputs-banner' ) {
                return <InputsOutputsBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.table-banner') {
                return <TableBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.image-and-quote-banner') {
                return <ImageAndQuoteBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.unordered-list-banner') {
                return <UnorderedListBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.detailed-datas-banner') {
                return <DetailedDatasBanner data={content} />;
            } else if (content.strapi_component === 'main-banners.branch-data-banner') {
                return <BranchDataBanner data={content} />;
            } else if ( content.strapi_component === 'main-banners.arrow-data-banner' ) {
                return <ArrowDataBanner data={content} />;
            } else if (content.strapi_component === 'main-banners.media-with-form-banner') {
                return <MediaWithFormBanner data={content} />;
            } else if (content.strapi_component === 'main-banners.resource-adviser') {
                return <ResourceAdviser data={content} />;
            } else if(content.strapi_component === 'business.icon-list') {
                return <IconTextList data={content}/>
            } else if (content.strapi_component === 'business.talk-to-us-banner') {
                return <TalkToUsBanner data={content} />
            } else if (content.strapi_component === 'component.simple-text') {
                return <SimpleText data={content} />
            } else if (content.strapi_component === 'business.embed-video') {
                return <EmbedVideoBanner data={content} />
            } else if (content.strapi_component === 'component.simple-image') {
                return <SimpleImage data={content} />
            } else if (content.strapi_component === 'training.core-program-overview') {
                return <CoreProgramOverviewBanner data={content} />
            } else if(content.strapi_component === 'training.quote-with-image-banner') {
                return <QuoteWithImageBanner data={content} />
            } else if(content.strapi_component === 'component.simple-table') {
                return <SimpleTable data={content}/>
            } else if(content.strapi_component === 'methodology.system-introduction') {
                 return <SystemIntroduction data={content} />
            } else if(content.strapi_component === 'component.multiple-cta-buttons') {
                return <MultipleCtaButtons data={content} />
            } else if(content.strapi_component === 'training.text-with-favicon') {
                return <TextWithFavicon data={content} />
            } else if(content.strapi_component === 'main-banners.data-column-banner') {
                return <DataColumnBanner data={content} />
            } else if(content.strapi_component === 'footer.footer-cta') {
                return <FooterCTA data={content} />
            } else if(content.strapi_component === 'main-banners.timeline-banner') {
                return <TimelineBanner data={content} />
            } else if(content.strapi_component === 'main-banners.data-with-multiple-image') {
                return <DataWithMultipleImage data={content} />
            } else if(content.strapi_component === 'component.complex-tab-panel') {
                return <ComplexTabPanel data={content} />
            }
            else {
                return (
                    <div>
                        <p>{props.type}</p>
                        <p>Component not implemented yet. - {content.strapi_component}</p>
                    </div>
                );
            }
        }),
        <Footer />,
    ];
    return <div>{page}</div>;
}
