import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"

export default function SimpleTable(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="simple-table">
                <Row className="top-text text-center mb-3">
                    <ReactMarkdown>{props.data.topText}</ReactMarkdown>
                </Row>
                <Row>
                    {props.data.tableColumns.map((column) => {
                        return (
                            <Col md={6}>
                                <p className="table-title">{column.name}</p>
                                {column.tableRows.map((row) => {
                                    return <ReactMarkdown>{row.freeText}</ReactMarkdown>
                                })}
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
    )
}
