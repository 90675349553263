import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { siteMetadata } from "../../../../gatsby-config";

export default function ImageListBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    // description align is from backend
    const textAlign = props.data.descriptionAlign ? " " + props.data.descriptionAlign.textAlign + "-align " : " "
    
    return (
        <section className={ background }>
            <Container className="text-center image-list-banner">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead banner-subtitle">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="content">
                    {props.data.imagesAndTexts &&
                        props.data.imagesAndTexts.map((item) => {
                            return (
                                <Col md={6} lg={4} key={item.id}>
                                    {item.image && <img className="w-75" src={siteMetadata.apiURL + item.image.url} alt="" />}
                                    <div className="text-wrapper">
                                        <p className="font-weight-bold w-75 mx-auto mt-4 item-title">{item.title}</p>

                                        <p className={"description w-75 m-auto" + textAlign}>
                                            <ReactMarkdown>{item.sublineText}</ReactMarkdown>
                                        </p>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
                {props.data.ctaButton && (
                    <a href={props.data.ctaButton.ButtonRoute} className="open-demo-modal btn btn-lg btn-green">
                        {" "}
                        {props.data.ctaButton.ButtonText}
                    </a>
                )}
            </Container>
        </section>
    );
}
