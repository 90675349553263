import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

export default function DataWithNotAutoVideoBanner(props) {
    const textPosition = props.data.videoOnRight  ? { span: 6, order: 0 } : { span: 6, order: 1 };
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background } >
            <Container className="data-not-video-banner">
                <Row className=''>
                    <Col md={textPosition}>
                        <div className='text-on-left'>
                            <h2 className="text-center text-md-left">{props.data.title}</h2>
                            <ReactMarkdown className="text-center text-md-left">{props.data.description}</ReactMarkdown>
                            <p>
                                <ul>
                                    {props.data.ListTextItem &&
                                        props.data.ListTextItem.map(
                                            (textItem) => {
                                                return (
                                                    <li key={textItem.id}>
                                                        {textItem.text}
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='embed-responsive embed-responsive-16by9'>
                            <div className='embed-responsive embed-responsive-16by9'>
                                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} >
                                    <iframe
                                        loading='lazy'
                                        src={props.data.videoUrl}
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        frameborder='0'
                                        allow='autoplay; fullscreen'
                                        allowfullscreen
                                    ></iframe>
                                </div>
                                <script src='https://player.vimeo.com/api/player.js'></script>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
