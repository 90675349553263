import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function InfoBlockBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <Container fluid className={'midpad ' + background}>
            {/* <!-- Title --> */}
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <h2 className="banner-title">{props.data.title}</h2>
                </Col>
                <Col lg={7} md={7} sm={12} xs={12}>
                    <p>
                        {props.data.text1}
                        <ul>
                            {props.data.listItem.map((item) => {
                                return <li>{item.text}</li>;
                            })}
                        </ul>
                    </p>
                </Col>
                <Col lg={5} md={5} sm={12} xs={12}>
                    <p>{props.data.text2}</p>
                    {props.data.button && <a id='Contact_Global_BTN' className='btn btn-medium btn-blue btn-left'>{props.data.button.ButtonText}</a>}
                </Col>
            </Row>
        </Container>
    );
}
