import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Col, Row } from "react-bootstrap";
import { siteMetadata } from "../../../../gatsby-config";

export default function SystemIntroduction(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " ";

    return (
        <section className="system-introduction">
            <Container className={background}>
                <Col>
                    <Row>
                        <ReactMarkdown className="banner-title">{props.data.title}</ReactMarkdown>
                    </Row>
                    <Row className="justify-content-between content">
                        <Col md={6}>
                            <Row>
                                <ReactMarkdown>{props.data.leftText}</ReactMarkdown>
                            </Row>
                            <Row>{props.data.leftImage && <img className="w-75 mx-auto" src={siteMetadata.apiURL + props.data.leftImage.url} alt="" />}</Row>
                            <Row>
                                {props.data.leftLink && (
                                    <a className="mx-auto" href={props.data.leftLink.ButtonRoute}>
                                        {props.data.leftLink.ButtonText}
                                    </a>
                                )}
                            </Row>
                        </Col>
                        <Col md={5}>
                            <p>{props.data.rightImageTitle}</p>
                            {props.data.rightImage && <img className="w-100" src={siteMetadata.apiURL + props.data.rightImage.url} alt="" />}
                            <ReactMarkdown>{props.data.rightBottomText}</ReactMarkdown>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </section>
    );
}
