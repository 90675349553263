import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { siteMetadata } from "../../../../gatsby-config";

export default function DividedDataBoxes(props) {
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    // width of the rightlist and leftlist col
    const rightlistWidth = props.data.RightList.length > 1 ? 6 : 12
    const leftlistWidth = props.data.LeftList.length > 1 ? 6 : 12

    return (
        <section className={ background }>
            <Container className="text-center divided-data-boxes">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    <Col lg={5} className="item d-flex flex-column align-items-center">
                        <div className="top-container">
                            <h3 className="mb-3">{props.data.leftTitle}</h3>
                            {props.data.leftImage && (
                                <div className="img-container d-flex align-items-center justify-content-center">
                                    <img src={siteMetadata.apiURL + props.data.leftImage.url} alt="" />
                                </div>
                            )}
                        </div>
                        <Row className="justify-content-center bottom-container flex-grow-1">
                            <ReactMarkdown className="w-75 mx-auto mt-4">{props.data.leftText}</ReactMarkdown>
                            {props.data.LeftList &&
                                props.data.LeftList.map((listItem) => {
                                    return (
                                        <Col key={listItem.id} lg={leftlistWidth} className="mt-4 box-item">
                                            <p className="m-auto item-description">
                                                <ReactMarkdown>{listItem.text}</ReactMarkdown>
                                            </p>
                                        </Col>
                                    );
                                })}
                        </Row>
                        {props.data.leftLink && (
                            <a className="text-uppercase link mt-4 btn btn-medium btn-blue-bx-hdr" href={props.data.leftLink.ButtonRoute}>
                                {props.data.leftLink.ButtonText}
                            </a>
                        )}
                    </Col>
                    <Col lg={5} className="item d-flex flex-column align-items-center">
                        <div className="top-container">
                            <h3 className="mb-3">{props.data.rightTitle}</h3>
                            {props.data.rightImage && (
                                <div className="img-container d-flex align-items-center justify-content-center">
                                    <img src={siteMetadata.apiURL + props.data.rightImage.url} alt="" />
                                </div>
                            )}
                        </div>
                        <Row className="justify-content-center bottom-container flex-grow-1">
                            <ReactMarkdown className="w-75 mx-auto mt-4">{props.data.rightText}</ReactMarkdown>
                            {props.data.RightList &&
                                props.data.RightList.map((listItem) => {
                                    return (
                                        <Col key={listItem.id} lg={rightlistWidth} className="mt-4 box-item">
                                            <p className="m-auto item-description">
                                                <ReactMarkdown>{listItem.text}</ReactMarkdown>
                                            </p>
                                        </Col>
                                    );
                                })}
                        </Row>
                        {props.data.rightLink && (
                            <a className="text-uppercase link mt-4 btn btn-medium btn-blue-bx-hdr" href={props.data.rightLink.ButtonRoute}>
                                {props.data.rightLink.ButtonText}
                            </a>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
