import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function ImageCarousel(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "


    return (
        <section className={"image-carousel mb-5" + background  }>
            <Container>
                <Row>
                    <Col className='narrower text-center'>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead'>{props.data.subtitle}</ReactMarkdown>
                        <div className='onboarding-carousel'>
                        <h3 className='infographic-heading'> {props.data.carouselHeadingText} </h3>
                        <Carousel>
                            {props.data.carouselContent.map((content) => {
                                return (
                                    <Carousel.Item>
                                        {content.carosuelImage && <img loading='lazy' src={siteMetadata.apiURL + content.carosuelImage.url} alt='...' />}
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
