import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function IconBanner(props) {
    
    const sectionTextCenterClassName = props.data.centeredTexts ? ' text-center ' : '';
    //This is not a good aproach, we have to dynamically set the col size, and not let the user give these kind of details
    const columnStyle = props.data.columns === 'five' ? { maxWidth: '20%' } : null;
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background + ' ' + sectionTextCenterClassName}>
            <Container className="icon-banner">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead mb-0'>{props.data.description}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className='features justify-content-center'>
                    {props.data.iconBox && props.data.iconBox.map((box) => {
                        return (
                            <Col md={4} lg={props.data.columns === 'four' ? 3 : 4} className='inline-item' style={columnStyle} key={box.id}>
                                {/* if there is uploaded image, this image is displayed. If there is fontawesome, the icon is displayed */}
                                {box.iconImage ? (
                                <p className="text-center">
                                    <img loading='lazy' src={siteMetadata.apiURL + box.iconImage.url} alt='project change management' />
                                </p>
                                )
                                :
                                 (<p className="text-center">
                                    <FontAwesomeIcon icon={["fal", box.fontAwesomeIconName]} className="icon" />
                                 </p>)}

                                <h3 className="icon-box-title">{box.IconBoxTitle}</h3>
                                <ReactMarkdown className="icon-box-description">{box.IconBoxDescription}</ReactMarkdown>
                            </Col>
                        );
                    })}
                </Row>
                <Row className="justify-content-center">
                    {props.data.ctaButton &&
                        props.data.ctaButton.map(button => {
                            return (
                                    <a key={button.id} href={button.ButtonRoute} className='open-demo-modal btn btn-lg btn-green mx-4 mt-5'> {button.ButtonText}</a>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    );
}
