import React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function ClassicLinksAndButtonsBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className='text-center classic-links-buttons-banner'>
                <Row className='text-center'>
                <Col md={12}>
                    <h2 className="banner-title">{props.data.title}</h2>
                    <ReactMarkdown className='lead banner-subtitle'>{props.data.subtitle}</ReactMarkdown>
                </Col>
                </Row>
                <Row className="content">
                {props.data.LinkTextAndButton &&
                    props.data.LinkTextAndButton.map((item) => {
                        return (
                            <Col sm={6} md={3} key={item.id} className="item">
                                {item.image && <img className="item-image" src={siteMetadata.apiURL + item.image.url} alt={item.image.alternativeText} />}
                                {item.button && <a href={item.button.ButtonRoute} className="item-link">{item.topic}</a>}
                                <p>{item.description}</p>
                                <div className="button-container">
                                    {item.button && <Button href={item.button.ButtonRoute} className="btn-blue-bx-hdr">{item.button.ButtonText}</Button>}
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
}
