import React from "react"
import { Container, Col, Row, Card } from "react-bootstrap"
import { siteMetadata } from "../../../../gatsby-config"
import ReactMarkdown from "react-markdown"

export default function IntroBoxes(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={"intro-boxes-container" + background}>
            <Container>
                <Row md={12} className="text-center">
                    <Col>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead banner-subtitle'>{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    {props.data.IntroCard &&
                        props.data.IntroCard.map((card) => {
                            return (
                                <Col sm={6} lg={4} className="mb-4" key={card.id}>
                                    <Card className="h-100">
                                        {card.logoImage && <Card.Img loading="lazy" variant="top" alt={card.logoImage.alternativeText} className="p-3 partner-logo" src={siteMetadata.apiURL + card.logoImage.url} />}
                                        <Card.Body>
                                            <p className="font-italic">{card.italicText}</p>
                                            <ReactMarkdown className="card-text">{card.description}</ReactMarkdown>
                                            <Row className="text-center btn-container">
                                                <Col xs={6}>{card.firstLink && <a href={card.firstLink.ButtonRoute}>{card.firstLink.ButtonText}</a>}</Col>
                                                <Col xs={6}>{card.secondLink && <a href={card.secondLink.ButtonRoute}>{card.secondLink.ButtonText}</a>}</Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })}
                </Row>
            </Container>
        </section>
    )
}
