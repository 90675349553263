import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function DataColumnBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={'text-center' + background} >
            <Container className="data-column-banner">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.bannerTitle}</h2>
                        <ReactMarkdown className='lead'>{props.data.bannerSubtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className='data-columns'>
                    {props.data.DataColumn && props.data.DataColumn.map(column=>{
                        return (
                            <Col key={column.id} className="data-column" md={4}>
                                <Row className='text-center'>
                                    <p className='column-title'>{column.columnTitle}</p>
                                </Row>
                                {column.columnItem && column.columnItem.map(item=>{
                                    return (
                                        <Row key={item.id} className='column-item'>
                                            {item.iconImage ?
                                                <img className='w-25 mx-auto' src={siteMetadata.apiURL + item.iconImage.url} alt="" />
                                                :
                                                (<p className="text-center">
                                                    <FontAwesomeIcon icon={["fal", item.fontAwesomeIconName]} className="icon" />
                                                </p>)}
                                            <p className="item-title">{item.IconBoxTitle}</p>
                                            <ReactMarkdown>
                                                {item.IconBoxDescription}
                                            </ReactMarkdown>
                                            <FontAwesomeIcon className="arrow-icon" icon={faArrowDown}></FontAwesomeIcon>
                                        </Row>  
                                    )
                                })}
                            </Col>
                        )
                    })}
                </Row>
                {props.data.ctaButton && <a href={props.data.ctaButton.ButtonRoute} className='open-demo-modal btn btn-lg btn-green'> {props.data.ctaButton.ButtonText}</a>}
            </Container>
        </section>
    )
}
