import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"

export default function TableBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="table-banner">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead banner-subtitle">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="text-center content">
                    <Col sm={6} className="item">
                        <p className="content-title">{props.data.leftColumnTitle}</p>
                        <div className="img-container">
                            {props.data.leftColumnImage && (
                                <img
                                    className="w-50"
                                    src={siteMetadata.apiURL + props.data.leftColumnImage.url}
                                    alt=""
                                />
                            )}
                        </div>
                        <ReactMarkdown>{props.data.leftColumnDescription}</ReactMarkdown>
                    </Col>
                    <Col sm={6} className="item">
                        <p className="content-title">{props.data.rightColumnTitle}</p>
                        <div className="img-container">
                            {props.data.rightColumnImage && (
                                <img
                                    className="w-50"
                                    src={siteMetadata.apiURL + props.data.rightColumnImage.url}
                                    alt=""
                                />
                            )}
                        </div>
                        <ReactMarkdown>{props.data.rightColumnDescription}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
