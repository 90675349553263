import React from "react";
import { Col, Row, Container } from "react-bootstrap";

/**
 * Basic box with an image which is a link and title, and description those are pasig paragraphs
 */
export default function MultipleCtaButtons(props) {
    return (
        <section className="multiple-cta cta-bar">
            <Container className="">
                <Row>
                    {props.data.ctaButtons && props.data.ctaButtons.map((button) => {
                        return (
                            <Col className="text-center btn-container d-flex align-items-center justify-content-center" md={props.data.columns === "four" ? 3 : props.data.columns === "three" ? 4 : 6} xs={12}>
                                {" "}
                                <a href={button.ButtonRoute} className="open-demo-modal btn btn-lg btn-green">
                                    {" "}
                                    {button.ButtonText}
                                </a>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
}
