import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { siteMetadata } from '../../../../gatsby-config';
import ReactMarkdown from 'react-markdown';

export default function DataWithVideoBanner(props) {
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={'shape-divider data-video-banner' + background}>
            <Container className="pt-5">
                <Row>
                    <Col md={6}>
                        {props.data.video && (
                            <video className='mb-30' width='100%' height='auto' autoPlay muted loop>
                                <source
                                    src={ siteMetadata.apiURL + props.data.video.url }
                                    type={props.data.video.mime}
                                />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </Col>

                    <Col md={6}>
                        <h3 className='mt-0'>{props.data.title}</h3>
                        <ReactMarkdown>{props.data.description}</ReactMarkdown>
                        <ul className='tick-bullets'>
                            {props.data.ListTextItem &&
                                props.data.ListTextItem.map((textItem) => {
                                    return (
                                        <li key={textItem.id}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            {textItem.text}
                                        </li>
                                    );
                                })}
                        </ul>
                        {props.data.dataWithVideoButton && (
                            <p className="d-flex d-md-block justify-content-center">
                                <a href={ props.data.dataWithVideoButton.ButtonRoute} className='open-demo-modal btn btn-medium btn-green btn-left m-auto'>
                                    {props.data.dataWithVideoButton.ButtonText}
                                </a>
                            </p>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
