import React from 'react';
import LinkBox from '../components/LinkBox';
import { Container, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';


/**
 * Simple component with title, subtitle and text from strapi in markdown
 */
export default function DetailsWithLinkBox(props) {
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background }>
            <Container className="details-with-link-box">
                <Row>
                    <div className='col-md-12 text-center'>
                        <h2 className="banner-title">{props.data.DetailsWithLinkBoxTitle}</h2>
                        <ReactMarkdown className='lead'>{props.data.DetailsWithLinkBoxSubTitle}</ReactMarkdown>
                        <ReactMarkdown>{props.data.DetailsWithLinkBoxDescription}</ReactMarkdown>
                        <ReactMarkdown className="mb-4 mt-4">{props.data.DetailsWithLinkBoxSubText}</ReactMarkdown>
                    </div>
                </Row>
                <Row className='call-out-boxes justify-content-center'>
                    { props.data.LinkBox && props.data.LinkBox.map((box) => {
                        return <LinkBox col={props.data.columns === 'three' ? 4 : 3} data={box} key={box.id}/>;
                    })}
                </Row>
            </Container>
        </section>
    );
}
