import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";

export default function TextWithFavicon(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " ";

    return (
        <section className={background}>
            <Container className="text-with-favicon my-4">
                <Row>
                    <Col md={1} className="d-flex justify-content-center mb-2">
                        <FontAwesomeIcon size={"2x"} icon={["fal", props.data.faviconName]} />
                    </Col>
                    <Col md={11}>
                        <ReactMarkdown>{props.data.text}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
