import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from "react-markdown";

export default function ComplexTabPanel(props) {
    const [displayedId, setDisplayedId] = useState(props.data.MultipleTabsOfTextsAndMedia && props.data.MultipleTabsOfTextsAndMedia[0] ? props.data.MultipleTabsOfTextsAndMedia[0].id : null);

    const handleButtonClick = (id) => {
        setDisplayedId(id);
    };

    let totalTab = (100 / props.data.MultipleTabsOfTextsAndMedia.length);

    let finalTabWidth = totalTab - 0.5 + '%';

    return (
        <div className="complex-tab-panel-widget">
            <div id='complex_tabs'>
                <Container fluid>
                    <Row className='wbg'>
                        <Container >
                            <div className="widget-title-description">
                                <h2>{props.data.primaryHeading}</h2>
                                <ReactMarkdown className="mb-5">{props.data.primaryText}</ReactMarkdown>
                            </div>
                            {/* <!-- TOGGLE TAB GROUP ONE --> */}
                            <ul className='nav nav-tabs'>
                                {props.data.MultipleTabsOfTextsAndMedia.map((tab) => {
                                    return (
                                        <li style={{width: finalTabWidth}} className={tab.id === displayedId ? 'general-tab active' : 'general-tab'}>
                                            <a data-toggle='tab' className='tabstyle' onClick={() => handleButtonClick(tab.id)}>
                                            {tab.tabHeading &&
                                                <div className="tab-with-heading"><FontAwesomeIcon size={"1x"} icon={["fal", tab.fontAwesomeIconName]} className="icon mr-0" /><div className="tab-heading-name">{tab.tabHeading}</div></div>
                                            }
                                                <div>{tab.tabTitle ? tab.tabTitle : ""}</div>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Container className='tab-content wbg' id='primary_tabs'>
                                <div>
                                    <Row id='menu_1a' className='tab-pane active fade in wbg w-100 m-auto'>
                                        {props.data.MultipleTabsOfTextsAndMedia.map((tab) => {
                                            return (
                                                <Col lg={12} md={12} sm={12} xs={12} className='wbg' style={{ display: tab.id === displayedId ? 'block' : 'none' }} >
                                                    <div className="tab-row-cols">
                                                        <div className="tab-col-left">
                                                            <h3>{tab.leftTextTitle}</h3>
                                                            <ReactMarkdown className="mb-2">{tab.leftContent}</ReactMarkdown>
                                                            {tab.button &&
                                                                <a className='btn btn-blue btn-small btn-left' href={tab.button.ButtonRoute} >
                                                                    {tab.button.ButtonText}
                                                                </a>
                                                            }
                                                        </div>
                                                        <div className="tab-col-center">
                                                            {tab.mediaBox && tab.mediaBox.width === null && (
                                                                <video className='mb-30' width='100%' height='auto' autoPlay muted loop controls>
                                                                    <source
                                                                        src={ siteMetadata.apiURL + tab.mediaBox.url }
                                                                        type={tab.mediaBox.mime}
                                                                    />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            )}
                                                            {tab.mediaBox && tab.mediaBox.width !== null && (
                                                                <img className="center-tab-img" key={tab.mediaBox} alt='' loading='lazy' src={siteMetadata.apiURL + tab.mediaBox.url} />
                                                            )}
                                                        </div>
                                                        {tab.rightBoxTitle &&
                                                            <div className="tab-col-right lightgrey-bg">
                                                                <h3>{tab.rightBoxTitle}</h3>
                                                                <div>
                                                                    {tab.listWithIconAndTitle.map((listBox) => {
                                                                        return (
                                                                            <div className="right-box-icon-list">
                                                                                <div className="icon-col"><FontAwesomeIcon size={"2x"} icon={["fal", listBox.FontAwesomeIconName]} className="icon mr-3" /></div>
                                                                                <div className="text-col"><ReactMarkdown>{listBox.text}</ReactMarkdown></div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                            </Container>
                        </Container>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
