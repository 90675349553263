import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'

export default function SimpleText(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return(
        <section className={background}>
            <Container className="simple-text">
                <Row>
                    <Col lg={12} style={{textAlign:props.data.align === 'center' ? 'center' : 'left'}}>
                        <ReactMarkdown remarkPlugins={[gfm]} skipHtml={false}>{props.data.freeText}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}