import React from 'react';
import { Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';


/**
 * Basic box with an image which is a link and title, and description those are pasig paragraphs
 */
export default function LinkBox(props) {

    return(
        <Col md={6} lg={props.col} className="d-flex justify-content-center flex-column">
            <a className="item equal-height d-flex flex-column" href={props.data.buttonRoute}>
                {props.data.LinkBoxImage !== null && <img loading="lazy" className="img-fluid" 
                    srcSet=""
                    sizes=""
                    src={siteMetadata.apiURL + props.data.LinkBoxImage.url}
                    alt="" />}
                <h3>{props.data.RichBoxTitle}</h3>              
                <p>{props.data.RichBoxDescription}</p>
                {props.data.buttonRoute !== null && <p className="d-flex mt-auto w-75 mx-auto btn-container"><a href={props.data.buttonRoute} target="_blank" className="btn btn-small btn-blue-bx-hdr mt-10 no-transform w-50">{props.data.buttonText}</a></p>} 
            </a>
        </Col>
    );
}