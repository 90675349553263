import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { siteMetadata } from "../../../../gatsby-config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

/**
 *
 *
 */

export default function CoreProgramOverviewBanner(props) {
    
    const showSideBox = (props.data.programPrice || props.data.programImage || props.data.programDuration || props.data.programDelivery) ? true : false;
    // if the programPrice, programImage, programDuration and programDelivery fields are empty the side-box is hidden

    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="core-program-overview">
                <Row className="justify-content-between">
                    <Col md={6} className="left-side">
                        <h2>{props.data.title}</h2>
                        <ReactMarkdown>{props.data.freeText}</ReactMarkdown>
                    </Col>
                    <Col md={6} xl={5} className="right-side">
                        <Col md={12} className={"right-side-content " + (showSideBox ? '' : 'right-side-content-on-top')}>
                            {props.data.bookImage && <img loading="lazy" className="img-fluid" src={siteMetadata.apiURL + props.data.bookImage.url} alt={props.data.bookImage.alternativeText} />}
                            {props.data.programInputBox && 
                            <Row>
                                <Col className="program-input-box">
                                    <Row className="box-header">
                                        <Col sm={12} >
                                            <p className="header-item">{props.data.programInputBox.textAboveTitle}</p>
                                        </Col>
                                        <Col >
                                            <h3 className="header-item">{props.data.programInputBox.title}</h3>
                                        </Col>
                                    </Row>
                                    
                                    {props.data.programInputBox.programLine.map(line => {
                                        return (
                                            <Row className="program-line align-items-center">
                                                <Col xs={5}>
                                                    <p className="date-info text-on-date">{line.textOnDate}</p>
                                                    <p className="date-info">{line.date}</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <p className="open-btn btn" > <a href={line.buttonUrl}>{line.buttonText}</a></p>
                                                </Col>
                                                <Col xs={4}>
                                                    <p className="m-0"><a className="register-link" href={line.linkUrl}>{line.linkText} <FontAwesomeIcon icon={ faAngleRight } /></a></p>
                                                </Col>
                                            </Row>

                                        )
                                    })}
                                </Col>
                            </Row>
                            }
                            <p>
                                {props.data.bookButton && <a href={props.data.bookButton.ButtonRoute} target="_blank" className="book-btn btn-green btn btn-medium mt-10">
                                    {props.data.bookButton.ButtonText}
                                </a>}
                            </p>
                        </Col>
                        {showSideBox && 
                        <Col md={11} xl={9} className="side-box">
                            <Row>
                                <Col md={6}>
                                    <p>
                                        <span>Price:</span> {props.data.programPrice}
                                    </p>
                                    {props.data.programImage && <p>
                                        <span>Certification badge:</span> <img loading="lazy" className="img-fluid" src={siteMetadata.apiURL + props.data.programImage.url} alt={props.data.programImage.alternativeText}  />
                                    </p>}
                                </Col>
                                <Col md={6}>
                                    <p>
                                        <span>Delivery:</span> {props.data.programDelivery}
                                    </p>
                                    <p>
                                        <span>Duration:</span> {props.data.programDuration}
                                    </p>
                                    {props.data.programWho !== null ? <p>
                                        <span>Who:</span> {props.data.programWho}
                                    </p> : null}
                                </Col>
                            </Row>
                        </Col>}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
