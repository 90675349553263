import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { siteMetadata } from "../../../../gatsby-config";

export default function QuoteWithImageBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="quote-with-image">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="mb-4 mb-md-5 banner-title">{props.data.title}</h2>
                    </Col>
                </Row>
                <Row className="mx-lg-5">
                    {props.data.embedVideo}
                    <Col md={7} className="d-flex justify-content-center">
                        {props.data.image && props.data.embedVideo === false && <img loading="lazy" src={siteMetadata.apiURL + props.data.image.url} alt={props.data.image.alternativeText} />}
                        {props.data.embedVideo === true &&
                            <div className='embed-responsive embed-responsive-16by9'>
                                <div className='embed-responsive embed-responsive-16by9'>
                                    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} >
                                        <iframe
                                            loading='lazy'
                                            src={props.data.videoUrl}
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            frameborder='0'
                                            allow='autoplay; fullscreen'
                                            allowfullscreen
                                            controls
                                        ></iframe>
                                    </div>
                                    <script src='https://player.vimeo.com/api/player.js'></script>
                                </div>
                            </div>
                        }
                    </Col>
                    <Col md={5} className="pt-3 quote-container">
                        <div className="quote">
                            <FontAwesomeIcon icon={faQuoteLeft} />
                            {props.data.quoteText}
                            <FontAwesomeIcon icon={faQuoteRight} />
                        </div>
                        <h3 className="mb-0">{props.data.name}</h3>
                        <p>{props.data.field}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
