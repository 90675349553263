import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"

export default function LogosWithLinksAndButtons(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="text-center logos-links-buttons-container">
                <Row className="text-center mb-3">
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead subtitle">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="top-container justify-content-center">
                    {props.data.logosAndLinks &&
                        props.data.logosAndLinks.map((logoAndLink) => {
                            return (
                                <Col sm={6} md={3} key={logoAndLink.id}>
                                    <div className="image-container">
                                        {logoAndLink.logo && <img src={siteMetadata.apiURL + logoAndLink.logo.url} alt="" className="mb-4" />}
                                    </div>
                                    <p className="italic-text title">{logoAndLink.italicText}</p>
                                    <a href={logoAndLink.linkUrl} className="text-center subtitle">
                                        {logoAndLink.linkText}
                                    </a>
                                </Col>
                            )
                        })}
                </Row>
                <Row className="w-100 mx-auto middle-container justify-content-center light-grey-bg ">
                    {props.data.videoLinks &&
                        props.data.videoLinks.map((videoLink) => {
                            return (
                                <Col className="mb-4" xs={12} sm={6} key={videoLink.id}>
                                    <Row className="d-flex justify-content-center video-container">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <p className="font-italic title mt-3 m-2">{videoLink.italicText}</p>
                                            {(videoLink.Image || videoLink.videoUrl) && videoLink.Image ? (
                                                <img loading="lazy" src={siteMetadata.apiURL + videoLink.Image.url} alt="..." />
                                            ) : (
                                                <iframe
                                                    className="video"
                                                    loading='lazy'
                                                    src={videoLink.videoUrl} title={videoLink.videoTitle}
                                                    frameborder='0'
                                                    allow='fullscreen'
                                                    allowfullscreen
                                                ></iframe>
                                            )}
                                            <p className="subtext title mt-3">
                                                {videoLink.linkUrl ? <a className="middle-link font-weight-bold" href={videoLink.linkUrl}>
                                                    {videoLink.linkText}
                                                </a>
                                                :
                                                videoLink.linkText}
                                            </p>
                                            
                                            <p className="description">{videoLink.description}</p>
                                        </div>
                                    </Row>
                                </Col>
                            )
                        })}
                </Row>
                <Row>
                    <p className="mx-auto bottom-text font-weight-bold">{props.data.bottomText}</p>
                </Row>
                <Row className="bottom-container justify-content-center">
                    {props.data.bottomLinks &&
                        props.data.bottomLinks.map((bottomLink) => {
                            return (
                                <a key={bottomLink.id} className="text-uppercase bottom-links btn btn-green mx-3" href={bottomLink.ButtonRoute}>
                                    {bottomLink.ButtonText}
                                </a>
                            )
                        })}
                </Row>
            </Container>
        </section>
    )
}
