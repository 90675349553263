import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function ImageAndQuoteBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background }>
            <Container className="image-quote-banner">
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead'>{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <p className='font-weight-bold secondary-title'>{props.data.description}</p>
                </Row>
                <Row>
                    <Col md={8} className='p-0 img-container'>
                        {props.data.image && <img src={siteMetadata.apiURL + props.data.image.url} alt='' />}
                    </Col>
                    <Col md={4} className='d-flex flex-column justify-content-center'>
                        <p>"{props.data.quote}"</p>
                        <p className='font-weight-bold'>{props.data.quoteAuthor}</p>
                        <p>{props.data.quoteDescription}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
