import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from "../../../../gatsby-config";
import gfm from 'remark-gfm'

/**
 * 
 * 
 */
export default function IconTextList(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>        
            <Container className='icon-text-list'>
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title d-flex flex-column flex-md-row align-items-center" style={{maxWidth:'100%'}}> <FontAwesomeIcon style={{marginRight:'10px'}} className="dynamic-icon" icon={["fal", props.data.FontAwesomeIconName]} />{props.data.title}</h2>
                        {props.data.subtitle && <p className='lead'>{props.data.subtitle} </p>}
                    </Col>
                </Row>
               {props.data.image !== null || props.data.textNextToImage ? <Row className="text-and-image-container">
                    <Col xs={12} md={props.data.image !== null ? 6 : 12}>
                        <ReactMarkdown remarkPlugins={[gfm]} skipHtml={false}>{props.data.textNextToImage}</ReactMarkdown>
                    </Col>
                    {props.data.image !== null ? <Col xs={12} md={6}>
                        <img loading='lazy' className='img-fluid' src={siteMetadata.apiURL + props.data.image.url} alt='Business'/>
                    </Col> : null}
                </Row> : null}
                <Row className="bottom-container">
                    <Col md={12}>
                        <ReactMarkdown className="text-center">{props.data.textUnderImage}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="bottom-text">
                    <Col xs={12} className="mt-3">
                        <ReactMarkdown>{props.data.columnPartTitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="w-75 bottom-text m-auto">
                    {props.data.textColumn && props.data.textColumn.map(text => {
                        return (
                            <Col>
                                <ReactMarkdown>{text.text}</ReactMarkdown>
                            </Col>
                        )
                    })}
                </Row>
                <Row className="justify-content-center">
                {props.data.ctaButton && <a href={props.data.ctaButton.ButtonRoute} className='open-demo-modal btn btn-lg btn-green'> {props.data.ctaButton.ButtonText}</a>}
                </Row>

            </Container>
        </section>
    );
}
