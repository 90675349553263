import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { siteMetadata } from "../../../../gatsby-config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FooterCTA(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <div className={"top-footer-container" + background}>
            <Container>
                <Row className="top-footer">
                    {props.data.CTACard &&
                        props.data.CTACard.map((card) => {
                            return (
                                <Col sm={4} key={card.id}>
                                    <Row className="justify-content-center justify-content-md-start">
                                        <a className="title" href={siteMetadata.apiURL + card.buttonUrl}>
                                            <p>{card.title}</p>
                                        </a>
                                    </Row>
                                    <Row className="content">
                                        <Col md={3} className="p-0 d-flex h-50">
                                            <FontAwesomeIcon icon={["fal", card.FontAwesomeIconName]} className="w-75 h-75 mx-auto" />
                                        </Col>
                                        <Col className="d-flex justify-content-between flex-column">
                                            <p className="card-description">{card.description}</p>
                                            <p className="btn-container text-lg-right">
                                                <a href={card.buttonUrl} className="btn btn-sm btn-secondary">
                                                    {card.buttonText}
                                                </a>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                </Row>
            </Container>
        </div>
    )
}
