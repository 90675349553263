import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { siteMetadata } from "../../../../gatsby-config";

export default function ResourceAdviser(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="resource-adviser">
                <Row>
                    <Col md={12} className="text-center">
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="content">
                {props.data.ImageTextLink &&
                    props.data.ImageTextLink.map((col) => {
                        return (
                            <Col md={6} lg={3} className="item" key={col.id}>
                                <Row className="align-items-center">
                                    {col.image !== null ? <Col xs={3}>
                                        <img className="w-100" src={siteMetadata.apiURL + col.image.url} alt="" />
                                    </Col> : null}
                                    <Col className="d-flex flex-column align-items-center">
                                        <p className="item-title text-center">{col.text}</p>
                                        <a className="item-link text-center" href={col.linkUrl}>{col.linkText}</a>
                                    </Col>
                                </Row>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
}
