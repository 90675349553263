import React from "react"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"
import grayCircle from "../../../assets/images/banner-grey-circle.png"
import blueCircle from "../../../assets/images/banner-blue-circle.png"

export default function SmallerImageBanner(props) {
    return props.data.image ? (
        <header className="masthead row flex-center" style={{ backgroundImage: "url(" + siteMetadata.apiURL + props.data.image.url + ")" }}>
            <img className="grey-circle" src={grayCircle} alt="Circle icon" />
            <img className="blue-circle" src={blueCircle} alt="Circle icon" />

            <div className="content">
                <div className="heading-wrap">
                    <h1 className="headtext">{props.data.title}</h1>
                </div>
                <div className="description">
                    <ReactMarkdown>{props.data.sublineText}</ReactMarkdown>
                </div>
                {props.data.bannerButton && (
                    <a style={{ margin: "10px" }} href={props.data.bannerButton.ButtonRoute} className="banner-button btn btn-medium btn-green mt-sm-4 mr-sm-4 d-block d-sm-inline-block">
                        {props.data.bannerButton.ButtonText}
                    </a>
                )}
            </div>
        </header>
    ) : null
}
