import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { siteMetadata } from "../../../../gatsby-config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import ReactMarkdown from "react-markdown"

export default function ArrowDataBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="text-center arrow-data-banner">
                <Row>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="content">
                    {props.data.columns &&
                        props.data.columns.map((col) => {
                            return (
                                <Col md={6} lg={3} className="item" key={col.id}>
                                    <Row className="img-container">
                                        {col.icon ? (
                                            <img className="w-25" src={siteMetadata.apiURL + col.icon.url} alt="" />
                                        ) : (
                                            <p className="text-center">
                                                {col.FontAwesomeIconName}
                                                <FontAwesomeIcon icon={["fal", col.fontAwesomeIconName]} className="icon" />
                                            </p>
                                        )}
                                    </Row>
                                    <Row>
                                        <p className=" font-weight-bold">{col.title}</p>
                                    </Row>
                                    <Row className="item-text">
                                        {col.listTexts &&
                                            col.listTexts.map((item) => {
                                                return <p>{item.text}</p>
                                            })}
                                        <FontAwesomeIcon className="arrow-icon" icon={faArrowDown}></FontAwesomeIcon>
                                    </Row>
                                </Col>
                            )
                        })}
                </Row>
            </Container>
        </section>
    )
}
