import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"

/**
 *
 *
 */
export default function TalkToUsBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="talk-to-us-banner">
                <Row className="justify-content-between">
                    <Col md={props.data.image ? 6 : 12}>
                        <Row className="text-center text-md-left">
                            <Col md={12}>
                                <h2>{props.data.title}</h2>
                                <p className="lead">{props.data.subtitle} </p>
                            </Col>
                        </Row>
                        <Row className="text-and-image-container">
                            <Col md={12} className="pl-4">
                                <ReactMarkdown>{props.data.bodyText}</ReactMarkdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-4 text-center text-md-left mt-4 mt-md-5">
                                {props.data.ctaButton && 
                                    <a href={props.data.ctaButton.ButtonRoute} className="open-demo-modal btn btn-lg btn-green">
                                        {props.data.ctaButton.ButtonText}
                                    </a>
                                }
                            </Col>
                        </Row>
                    </Col>
                    {props.data.image && <Col md={5} className=''>
                        {props.data.image && <img className='img-fluid w-100' src={siteMetadata.apiURL + props.data.image.url} alt="" />}
                    </Col>}
                </Row>
            </Container>
        </section>
    )
}
