import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function NumberedListBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={"pb-0" + background}>
            <Container className="numbered-list-banner">
                <Row>
                    <Col md={12} className='text-center'>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead'>{props.data.description}</ReactMarkdown>
                    </Col>
                </Row>
                <ol className="mb-0">
                    <Row md={12}>
                        <Col md={6}>
                            <li className='my-4'>
                                {props.data.ComplexList && props.data.ComplexList[0] && props.data.ComplexList[0].text}
                            </li>
                            <li className='my-4'>
                                {props.data.ComplexList && props.data.ComplexList[1] && props.data.ComplexList[1].text}
                                <div className='w-75 my-4 quote-container'>
                                <p className='font-italic d-inline quote'>
                                    {props.data.ComplexList && props.data.ComplexList[1] && props.data.ComplexList[1].quote}
                                    <p className='font-weight-bold d-inline author'>
                                    {props.data.ComplexList[1] && ' - ' + props.data.ComplexList[1].quoteAuthor}
                                    </p>
                                </p>
                                </div>
                            </li>
                        </Col>
                        <Col md={6}>
                        <li className='my-4'>
                            {props.data.ComplexList && props.data.ComplexList[2] && props.data.ComplexList[2].text}
                            {props.data.ComplexList && props.data.ComplexList[2] && props.data.ComplexList[2].ListItemWithIcon.map((item) => {
                                return (
                                    <Row className='my-3' key={item.id}>
                                    <Col xs={12} sm={2} className="image-wrapper">
                                        {item.icon && (
                                        <img
                                            className='img-fluid'
                                            src={siteMetadata.apiURL + item.icon.url}
                                            alt=''
                                        />
                                        )}
                                    </Col>
                                    <Col>
                                        <p className='font-weight-bold'>{item.text}</p>
                                    </Col>
                                    </Row>
                                );
                            })}
                        </li>
                        </Col>
                    </Row>
                    <Row>
                        {props.data.ComplexList &&
                        props.data.ComplexList.map((listItem, index) => {
                            if (index > 2) {
                                return (
                                    <Col md={6} key={listItem.id}>
                                    <li className='my-4'>{listItem.text}</li>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                </ol>
            </Container>
        </section>
    );
}
