import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';
import ReactMarkdown from 'react-markdown';

export default function DataWithImageBanner(props) {
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "
    const textPosition = props.data.imagePositionRight ? { span: 5, order: 1 } : { span: 5, order: 0 };
    const imageType = props.data.imagePortrait == true ? 'portrait' : 'landscape';
    const backgroundImageToBackground = props.data.backgroundImage ? props.data.backgroundImage.url : '';
    const className = props.data.backgroundImage ? 'quote-banner-with-background' : "";

    return (
        <section className={background + ' ' + className} style={{ backgroundImage: "url(" + siteMetadata.apiURL + backgroundImageToBackground + ")" }}>
            <Container className=' fade_a pt-0 pb-0 data-image-banner'>
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title mb-5">{props.data.bannerTitle}</h2>
                        <ReactMarkdown className='lead mb-5'>{props.data.bannerSubtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col lg={imageType === 'portrait' ? 3 : 6} md={textPosition} className='float-left text-center'>
                        <p className='text-center font-weight-bold'>
                            {props.data.imageTitle}
                        </p>
                        {props.data.image && (
                            <p className="d-flex d-md-block justify-content-center">
                                <img
                                    loading='lazy'
                                    className= {imageType === 'landscape' ? 'img-fluid' : 'img-fluid custom-portrait-image' }
                                    src={
                                        siteMetadata.apiURL +
                                        props.data.image.url
                                    }
                                    alt='Virtual Change Management Certification'
                                />
                            </p>
                        )}
                        {props.data.linkUnderImage && (
                            <a className='mt-5 d-inline-block btn btn-medium btn-green' href={ siteMetadata.apiURL + props.data.linkUnderImage.ButtonRoute}>
                                {props.data.linkUnderImage.ButtonText}
                            </a>
                        )}
                    </Col>
                    <Col lg={imageType === 'portrait' ? 9 : 6} md={6} className="float-left">
                        <div className=''>
                            <h3>{props.data.descriptionTitle}</h3>
                            <ReactMarkdown>
                                {props.data.description}
                            </ReactMarkdown>
                            {props.data.button && (
                                <p className="d-flex d-md-block justify-content-center text-center">
                                    <a className='btn btn-medium btn-green' href={props.data.button.ButtonRoute}>
                                        {props.data.button.ButtonText}
                                    </a>
                                </p>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
