import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function TwoImageWithLinkBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={'text-center two-images-link-banner-container' + background}>
            <Container>
                <Row>
                    <Col>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead banner-subtitle'>{props.data.description}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="light-grey-bg pt-4">
                    <Col md={6} className="image-container">
                        <p className='mx-5  font-weight-bold image-title'>{props.data.imageText1}</p>
                        <div className="image-wrapper">
                            {props.data.image1 && <img className='mb-3' src={siteMetadata.apiURL + props.data.image1.url} alt={props.data.image1.alternativeText} />}
                        </div>
                    </Col>
                    <Col md={6} className="image-container">
                        <p className='mx-5 mt-3 font-weight-bold image-title'>
                        {props.data.imageText2}
                        </p>
                        <div className="image-wrapper">
                            {props.data.image2 && <img className='mb-3' src={siteMetadata.apiURL + props.data.image2.url} alt={props.data.image2.alternativeText}/>}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col sm={7} className='mt-5'>
                    <ReactMarkdown>{props.data.subtext}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='mt-3 text-uppercase'>
                        {props.data.link && <a className="btn-blue-bx-hdr btn btn-primary" href={props.data.link.ButtonRoute}>{props.data.link.ButtonText}</a>}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
