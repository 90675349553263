import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { siteMetadata } from "../../../../gatsby-config"

export default function NumberedListSimpleBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className="simple-numbered-list">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ol>
                            {props.data.list &&
                                props.data.list.map((listItem) => {
                                    return (
                                        <li key={listItem.id}>
                                            <ReactMarkdown>{listItem.text}</ReactMarkdown>
                                        </li>
                                    )
                                })}
                        </ol>
                    </Col>
                </Row>
                <Row className="bottom-container mt-3 text-center">
                    {props.data.bottomCTAImage && (
                        <Col md={2}>
                            <img src={siteMetadata.apiURL + props.data.bottomCTAImage.url} alt="" className="w-100" />
                        </Col>
                    )}
                    {props.data.bottomCTAText && (
                        <Col md={8}>
                            <ReactMarkdown>{props.data.bottomCTAText}</ReactMarkdown>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    )
}
