import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from '../../../../gatsby-config';

export default function DesignedInfosWithImage(props) {
    const textPosition = props.data.imagePositionRight ? { span: 7, order: 1 } : { span: 7, order: 0 };
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background}>
            <Container className='designed-info'>
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <p className='lead banner-subtitle'>
                            <ReactMarkdown>{props.data.subtitle}</ReactMarkdown>
                            {' '}
                            {props.data.subtitleIconUrl && props.data.subtitleIcon && <a href={props.data.subtitleIconUrl}>
                                <img className='bg-dark' src={siteMetadata.apiURL + props.data.subtitleIcon.url} alt='' />
                            </a>}
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center content">
                    <Col  order={1} lg={textPosition} className='description-container'>
                        {props.data.DesignedInfo &&
                            props.data.DesignedInfo.map((infoline) => {
                                return (
                                    <Row key={infoline.id}>
                                        <Col xs={12} sm={3} className='side-container' >
                                            <Row className='mb-4 light-grey-bg'>
                                                <Col xs={4} sm={1} className=' p-0 image-wrapper' >
                                                    {infoline.boxIcon && (
                                                        <img className='' src={siteMetadata.apiURL + infoline.boxIcon.url} alt='' />
                                                    )}
                                                </Col>
                                                <Col xs={8} sm={11}>
                                                    {infoline.boxKeyWords &&
                                                        infoline.boxKeyWords.map(
                                                            (word) => {
                                                                return (
                                                                    <p key={word.id} className='text-uppercase font-weight-bold m-0'>{word.text}</p>
                                                                );
                                                            }
                                                        )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='description'>
                                            <ReactMarkdown>
                                                {infoline.description}
                                            </ReactMarkdown>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                    <Col lg={4} className='text-center image-container'>
                        <h3 className='w-75 mr-auto ml-auto image-title'>
                            {props.data.imageTitle}
                        </h3>
                        {props.data.image && (
                            <img src={siteMetadata.apiURL + props.data.image.url} alt=''/>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
