import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';
import ReactMarkdown from "react-markdown";

export default function DataWithMultipleImage(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " ";

    return (
        <section className={background}>
            <Container className="multiple-image-banner">
                <div className="text-sm-left">
                    <Col md={12} className="text-with-image-fluid-row">
                        <div className="text-side">
                            <h3>{props.data.title}</h3>
                            <p><ReactMarkdown>{props.data.description}</ReactMarkdown></p>
                        </div>
                        <div className="images-col">
                            {props.data.images.map((imageList) => {
                                return (
                                    <div className="image-list">
                                        {imageList && <img loading='lazy' src={siteMetadata.apiURL + imageList.url} alt='...' />}
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                </div>
            </Container>
        </section>
    );
}