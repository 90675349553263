import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';

export default function InfoCardsBanner(props) {
    return (
        <Container fluid className='midpad gbg fade_b'>
            <Row>
                {props.data.Infocard.map((infocard) => {
                    return (
                        <Col lg={3} md={6} sm={12} xs={12} className='fade_b'>
                            <div className='training_lowerpnl'>
                                {infocard.moreButton && <h3><a href={infocard.moreButton.ButtonRoute}>{infocard.title}</a></h3>}
                                {infocard.moreButton && <a href={infocard.moreButton.ButtonRoute}>
                                    {infocard.image && 
                                        <img
                                            loading='lazy'
                                            src={siteMetadata.apiURL + infocard.image.url}
                                            className='img-fluid'
                                            alt={infocard.image.alternativeText}
                                        />}
                                </a>}
                                <div className='shim_a' />
                                <p>{infocard.description}</p>
                                <div className='card_cta_bottom'>
                                    <div className='rsrc_line' />
                                    <div className='text-center'>
                                        {infocard.moreButton && <a className='btn btn-small btn-green btn-middle' href={infocard.moreButton.ButtonRoute }>{infocard.moreButton.ButtonText}</a>}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}
