import React from "react"
import { Container, Row, Col } from "react-bootstrap"

export default function ButtonBanner(props) {
    return (
        <section className="dark-grey-bg cta-bar">
            <Container className="button-banner">
                <Row className="text-center">
                    <Col md={12} className="d-md-flex justify-content-center">
                        <p className="h3 text-center">{props.data.title} </p>
                        <div className="d-flex align-items-center justify-content-center">
                            {props.data.button && (
                                <a href={props.data.button.ButtonRoute} className="open-demo-modal btn btn-lg btn-green btn-left mb-0">
                                    {props.data.button.ButtonText}
                                </a>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
