import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form'
import ReactMarkdown from 'react-markdown';

export default function MediaWithFormBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={background} >
            <Container className="media-form-banner">
                <Row className='text-center'>
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead mb-5 title'>{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col md={7} className="media-container">
                        <Row>
                            <p className='font-weight-bold'>{props.data.infoTitle}</p>
                            <p  className="mb-4">{props.data.infoDescription}</p>
                            <div className='video embed-responsive embed-responsive-16by9' >
                                <iframe
                                    loading='lazy'
                                    src={props.data.videoURL} title={props.data.videoTitle}
                                    frameborder='0'
                                    allow='autoplay; fullscreen'
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </Row>
                    </Col>
                    <Col md={5} className="light-grey-bg">
                        <HubspotForm
                            portalId='2401974'
                            formId='214dbd9a-24c5-46e0-a3b3-224343e2c4d6'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
