import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

export default function StatisticsBanner(props) {
    const background = props.data.backgroundSelection ? props.data.backgroundSelection.backgroundColor + "-bg " : " "
    
    return (
        <section className={ background }>
            <Container className="statistics-banner">
                <Row>
                    <Col md={12} className='text-center numbers'>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className='lead'>{props.data.description}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className='text-center mt-30'>
                    {props.data.statisticBox && props.data.statisticBox.map((box) => {
                        return (
                            <Col sm={4} key={box.id} className="mb-3">
                                <p className="counter-title-top">{box.title}</p>
                                <p className='counter-count'><span className='counter-count-number'> {box.value} </span> {box.unit}</p>
                                <p className='counter-title'>{box.name}</p>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
}
