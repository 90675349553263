import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { siteMetadata } from '../../../../gatsby-config';

export default function QuoteBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " ";
    const backgroundImageToBackground = props.data.backgroundImage ? props.data.backgroundImage.url : '';
    const className = props.data.backgroundImage ? 'quote-banner-with-background' : "";

    return(
        <section className={background + ' ' + className} style={{ backgroundImage: "url(" + siteMetadata.apiURL + backgroundImageToBackground + ")" }}>
            <Container className="quote-banner">
                <Row>
                    <div className="quote-wrap text-center text-sm-left">
                        <Col md={8}>
                            <div className="quote"><FontAwesomeIcon icon={faQuoteLeft}/> {props.data.quoteText} <FontAwesomeIcon icon={faQuoteRight}/></div>
                        </Col>
                        <Col md={4}>
                            <div className="author">
                                <h3>{props.data.quotePersonName}</h3>
                                <p>{props.data.description}</p>
                                {props.data.quoteButton !== null && <p><a href={props.data.quoteButton.ButtonRoute} target="_blank" rel="noreferrer" className="btn btn-medium btn-blue-bx-hdr mt-10 no-transform">{props.data.quoteButton.ButtonText}</a></p>}
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </section>
    );
}