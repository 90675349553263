import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { siteMetadata } from "../../../../gatsby-config";

export default function TitleAndDescriptionBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " ";

    return (
        <div>
            <section className={background}>
                <Container>
                    {props.data.imageLogo ?
                        <Row>
                            <Col md={12} className="title-and-description-with-image-widget">
                                <div className="content-col">
                                    <h2>{props.data.title}</h2>
                                    <ReactMarkdown>{props.data.subtitle}</ReactMarkdown>
                                </div>
                                {props.data.imageLogo && <div className="img-col"><img loading='lazy' className='img-fluid' src={siteMetadata.apiURL + props.data.imageLogo.url} alt={props.data.imageLogo.alternativeText} /></div>}
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col md={12} className="banner-with-image-widget">
                                <div>
                                    <h2 className="banner-title">{props.data.title}</h2>
                                    <ReactMarkdown className='lead'>{props.data.subtitle}</ReactMarkdown>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </section>
        </div>
    );
}
