import React from 'react';
import { Row } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';

/**
 * 
 * 
 */
export default function SimpleImage(props) {
    return (
        <Row className=''>
            {props.data.simpleImage && <img loading='lazy' className='img-fluid' src={siteMetadata.apiURL + props.data.simpleImage.url} alt={props.data.simpleImage.alternativeText}/>}
        </Row>
    );
}
