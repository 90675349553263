import React from 'react';
import { Carousel } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';

/**
 * 
 * Carosuel component with title, and images inside the carosuel.
 */
export default function CarosuelBanner(props) {
    return (
        <div className='logo-carousel'>
            <h3 className='text-center px-4'>{props.data.title}</h3>
            <Carousel id='logo-carousel' indicators={false}>
                {props.data.carosuelPage && props.data.carosuelPage.map((group) => {
                    return (
                        <Carousel.Item className='item' key={group.id}>
                        <div className='logo-wrap'>
                            {group.carosuelContent.map((link) => {
                                if (link.carosuelImage) {
                                    return (
                                        <img key={link.id} alt='' loading='lazy' src={siteMetadata.apiURL + link.carosuelImage.url} />
                                    );
                                } else {
                                    return null
                                }
                            })}
                        </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
}
