import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 *
 */
export default function EmbedVideoBanner(props) {
    return (
        <section className="light-grey-bg">
            <Container className="embed-video-banner">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="banner-title">
                            {" "}
                            <FontAwesomeIcon className="dynamic-icon" icon={["fal", props.data.FontAwesomeIconName]} />
                            {props.data.title}
                        </h2>
                        <p className="subtitle lead">{props.data.subtitle}</p>
                    </Col>
                </Row>
                <Row className="video-container justify-content-center mb-5">
                    <Col xs={12} md={props.data.image !== null ? 6 : 12}>
                        {parse(props.data.embedCode)}
                    </Col>
                </Row>
                <Row className="bottom-container">
                    <Col md={12}>
                        <ReactMarkdown>{props.data.freeText}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
