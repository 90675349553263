import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

export default function InputsOutputsBanner(props) {
    // background color from the backend
    const background = props.data.backgroundSelection ? " " + props.data.backgroundSelection.backgroundColor + "-bg " : " "

    return (
        <section className={ background }>
            <Container className="inputs-outputs-banner">
                <Row className="text-center">
                    <Col md={12}>
                        <h2 className="banner-title">{props.data.title}</h2>
                        <ReactMarkdown className="lead">{props.data.subtitle}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReactMarkdown className="text-center mb-5">{props.data.mainText}</ReactMarkdown>
                    </Col>
                </Row>
                <Row className="secondary-list-row justify-content-center">
                    <Col md={5} className="secondary-list mr-1">
                        <p className="title"> {props.data.leftListTitle}</p>
                        {props.data.leftListWithIcon &&
                            props.data.leftListWithIcon.map((item) => {
                                console.log("item", item);
                                return (
                                    <Row key={item.id} className="mb-3">
                                        <Col xs={1}>
                                            <FontAwesomeIcon size={"2x"} icon={["fal", item.FontAwesomeIconName]} />
                                        </Col>
                                        <Col xs={11} md={10} lg={11}>
                                            <p className="mb-0">
                                                <ReactMarkdown>{item.text}</ReactMarkdown>{" "}
                                            </p>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                    <Col md={5} className="secondary-list ml-1">
                        <p className="title">{props.data.rightListTitle}</p>
                        <ol className="p-0">
                            {props.data.rightList &&
                                props.data.rightList.map((item) => {
                                    return (
                                        <Row className="mb-3">
                                            <Col xs={1}>
                                                <FontAwesomeIcon icon={["fal", item.FontAwesomeIconName]} />
                                            </Col>
                                            <Col xs={11} md={10} lg={11}>
                                                <li key={item.id}>
                                                    <ReactMarkdown>{item.text}</ReactMarkdown>
                                                </li>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </ol>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
