import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { siteMetadata } from '../../../../gatsby-config';

export default function TabsWithTextButtonAndImage(props) {
    const [displayedId, setDisplayedId] = useState(props.data.TextImageTab && props.data.TextImageTab[0] ? props.data.TextImageTab[0].id : null);

    const handleButtonClick = (id) => {
        setDisplayedId(id);
    };

    return (
        <div>
            <div id='training_tabs'>
                <Container fluid className='midpad gbg'>
                    <Row className='wbg'>
                        <Container fluid className='gbg'>
                            {/* <!-- TOGGLE TAB GROUP ONE --> */}
                            <ul className='nav nav-tabs container-fluid'>
                                {props.data.TextImageTab.map((tab) => {
                                    return (
                                        <li className={'tabstyle' + tab.id === displayedId ? ' active' : ''}>
                                        <a data-toggle='tab' className='tabstyle' onClick={() => handleButtonClick(tab.id)}>
                                            {tab.tabTitle}
                                        </a>
                                        </li>
                                    );
                                })}
                            </ul>
                                <Container fluid className='tab-content wbg' id='primary_tabs'>
                                    {/* <TrainingTopTabBookSC /> */}
                                    <div>
                                        <Row id='menu_1a' className='tab-pane active fade in wbg w-100 m-auto'>
                                            {props.data.TextImageTab.map((tab) => {
                                                return (
                                                    <Col lg={6} md={6} sm={12} xs={12} className='wbg' style={{display: tab.id === displayedId ? 'block' : 'none'}} >
                                                        <h3>{tab.textTitle}</h3>
                                                        <p>{tab.description}</p>
                                                        {tab.button &&
                                                            <a className='btn btn-small btn-green btn-left' href={tab.button.ButtonRoute} >
                                                                {tab.button.ButtonText}
                                                            </a>
                                                        }
                                                    </Col>
                                                );
                                            })}
                                            <Col lg={6} md={6} sm={12} xs={12} className='wbg'>
                                                {props.data.TextImageTab && props.data.TextImageTab.map((tab) => {
                                                    return (
                                                        tab.image ? <img loading='lazy' src={siteMetadata.apiURL + tab.image.url} className='img-fluid' style={{display: tab.id === displayedId ? 'block' : 'none'}} /> : null
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            <div className='shim_a' />
                            <div id='contextual_tabs' />
                        </Container>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
